import React from 'react'

import HomeCarousel from '../components/HomeCarousel';
import Conoceme from '../components/Conoceme';
import Propuestas from '../components/Propuestas';
import Doname from '../components/Doname';
// import Prensa from '../components/Prensa';

const Home = () => {
    return (
        <div>
            <h1 className="principalHeader">Gonzalo Chacón Larraín</h1>
            <HomeCarousel />
            <Conoceme />
            <Doname />
            <Propuestas />
            {/* <Prensa /> */}
        </div>
    )
}

export default Home;